import React, { useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import theme from '../../themes/themeMaterial';
import useStyles from './styles';

import { ReactComponent as LogoSVG } from '../../platform/assets/images/logo.svg';
import { RESET_PASSWORD_URL } from '../../lib/utils';

const EMAIL_MAX_LENGHT = 254;

const emailIsBadFormatted = (value) => {
  const emailFormat = new RegExp('^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$');

  if (value) {
    if (value.length >= EMAIL_MAX_LENGHT || emailFormat.test(value.trim()) === false) {
      return true;
    }
  }
  return false;
};

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);
const {
  Form,
} = withTypes();

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const checkError = (error) => {
    if (typeof error === 'string') {
      return error;
    }
    if (error.message) {
      return error.message;
    }
    return 'warning';
  };

  const defaultHandleSubmit = (auth) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : '/').catch(
      (error) => {
        setLoading(false);
        notify(checkError(error));
      },
    );
  };

  const { onSubmit } = props;
  const handleSubmit = onSubmit || defaultHandleSubmit;

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }

    if (emailIsBadFormatted(values.username)) {
      errors.username = translate('ra.validation.email');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const onClickResetPassword = () => {
    window.location.href = RESET_PASSWORD_URL();
  };

  /* eslint-disable no-shadow */
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.header}>
                <div className={classes.logo}>
                  <div id="brand-logo-nav" align="center" spacingy="l2">
                    <LogoSVG />
                  </div>
                </div>
              </div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="username"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('ra.auth.username')}
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="outlined"
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
              <div className={classes.resetPassword}>
                {/* eslint-disable-next-line */}
                <span onClick={e => onClickResetPassword(e)}>
                  {translate('app.recoverPassword')}
                </span>
              </div>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};
/* eslint-enable no-shadow */

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = props => (
  <ThemeProvider theme={createTheme(theme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
