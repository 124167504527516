import React, { cloneElement } from 'react';
import {
  TopToolbar, ExportButton, sanitizeListRestProps, CreateButton,
} from 'react-admin';
import _ from 'lodash';
import CreateEmptyButton from './CreateEmptyItemButton';
import { PROMOTIONS, RETAILERS } from '../../resources/retailers/constants';

const ZERO = 0;

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  module,
  ...rest
}) => {
  let create = null;
  if (hasCreate) {
    create = (module && (module === PROMOTIONS || module === RETAILERS))
      ? <CreateEmptyButton basePath={basePath} {...rest} resource={resource} data-testid={`create-${resource}-button`} module={module} />
      : <CreateButton basePath={basePath} data-testid={`create-${resource}-button`} />;
  }

  const disabledExport = !!(((_.isEmpty(filterValues) && resource === 'comments') || total === ZERO));
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters
      && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {create}
      {exporter
      && (
      <ExportButton
        disabled={disabledExport}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
        data-testid={`export-${resource}-button`}
      />
      )
    }
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export default ListActions;
