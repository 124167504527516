import React from 'react';
import { Route } from 'react-router-dom';
import BlackList from './resources/blackList/BlackList';
import UploadCsv from './resources/products/UploadCsv';
import JobsList from './resources/products/JobsList';
import JobDetail from './resources/products/JobDetail';
import Syndication from './resources/products/Syndication';
import ShufflePage from './resources/shuffle/index';

export default [
  <Route exact path="/blacklist" component={BlackList} />,
  <Route exact path="/shuffle" component={ShufflePage} />,
  <Route exact path="/products/uploadCsv" component={UploadCsv} />,
  <Route exact path="/products/jobsList" component={JobsList} />,
  <Route exact path="/products/job/:jobId" component={JobDetail} />,
  <Route exact path="/products/job/" component={JobDetail} />,
  <Route exact path="/products/syndication" component={Syndication} />,
];
