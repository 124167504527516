import {
  BooleanInput, DateInput, Edit, FormDataConsumer, SimpleForm, TextInput, useTranslate,
} from 'react-admin';
import React from 'react';

import UploadImage from '../../lib/components/UploadImage';
import UserValidation from './UserValidation';
import UserTitle from './UserTitle';


const UserDetailTab = (props) => {
  const translate = useTranslate();

  return (
    <Edit mutationMode="undoable" title={<UserTitle />} {...props}>
      <SimpleForm>
        <FormDataConsumer>
          {({ formData }) => (
            <UploadImage
              {...props}
              id={formData.id}
              originalUrl={formData.imageUrl}
              source="imageUrl"
              accept="image/jpeg,image/png,image/jpg"
              maxSize={5000000}
              label={translate('user.edit.addImage')}
              placeholder={<p>Drop your image here</p>}
            />
          )}
        </FormDataConsumer>
        <TextInput
          source="username"
          fullWidth
          validate={UserValidation.validateUsername}
          resettable
          label={translate('user.edit.username')}
        />
        <TextInput
          fullWidth
          source="name"
          validate={UserValidation.validateName}
          resettable
          label={translate('user.edit.name')}
        />
        <TextInput
          fullWidth
          source="lastName"
          validate={UserValidation.validateLastName}
          resettable
          label={translate('user.edit.lastname')}
        />
        <BooleanInput
          source="isAdmin"
          label={translate('user.edit.isAdmin')}
        />
        <BooleanInput
          source="active"
          label={translate('user.edit.activated')}
        />
        <BooleanInput
          source="conditions"
          disabled
          label={translate('user.edit.conditions')}
        />
        <DateInput
          source="createdAt"
          disabled
          label={translate('user.edit.createdAt')}
        />
        <DateInput
          source="updatedAt"
          disabled
          label={translate('user.edit.updatedAt')}
        />
        <DateInput
          source="activatedAt"
          disabled
          label={translate('user.edit.activatedAt')}
        />
      </SimpleForm>
    </Edit>
  );
};

export default UserDetailTab;
