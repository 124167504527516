import * as React from 'react';
import {
  useEditController,
  useTranslate,
  TextInput,
  SimpleForm,
  BooleanInput,
  DateField,
  TextField,
  SelectInput,
  SaveButton,
  Toolbar, BooleanField,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { commentEditStyles } from './styles';

const REJECTED = 'REJECTED';
const PUBLISHED = 'PUBLISHED';

const STATUS_TYPES = {
  REJECTED,
  PUBLISHED,
};

const CommentEditToolbar = (props) => {
  const { pristine } = props;
  return (
    <Toolbar {...props}>
      <SaveButton disabled={pristine} />
    </Toolbar>
  );
};

const CommentEdit = ({ onCancel, id, ...props }) => {
  const classes = commentEditStyles();
  const newProps = {
    ...props,
    id,
  };
  const controllerProps = useEditController(newProps);
  const translate = useTranslate();


  if (!controllerProps.record) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography varian="h6">
          {translate('comment.edit.pageTitle')}
        </Typography>
        <IconButton onClick={onCancel} />
      </div>
      <SimpleForm
        className={classes.form}
        basePath={controllerProps.basePath}
        record={controllerProps.record}
        save={controllerProps.save}
        version={controllerProps.version}
        redirect="list"
        resource="comments"
        toolbar={<CommentEditToolbar />}
      >
        <TextField source="ean" label={translate('comment.edit.ean')} />
        <TextField source="rating" label={translate('comment.edit.rating')} />
        <TextField source="purchasedAt" label={translate('comment.edit.purchasedAt')} />
        <TextField source="author.authorName" label={translate('comment.edit.authorName')} />
        <TextField source="author.email" label={translate('comment.edit.authorMail')} />
        <BooleanField source="author.anonymous" label={translate('comment.edit.authorAnonymous')} />
        <TextField source="author.platform" label={translate('comment.edit.authorPlatform')} />
        <TextField source="author.country" label={translate('comment.edit.authorCountry')} />
        <TextField source="author.language" label={translate('comment.edit.authorLanguage')} />
        <DateField source="createdAt" label={translate('comment.edit.createdAt')} />
        <SelectInput
          source="status"
          choices={[
            { id: STATUS_TYPES.REJECTED, name: 'Rejected' },
            { id: STATUS_TYPES.PUBLISHED, name: 'Published' },
          ]}
          label={translate('comment.edit.status')}
        />
        <BooleanInput source="certified" label={translate('comment.edit.certified')} />
        <TextField source="platform" label={translate('comment.edit.platform')} />
        <TextField source="country" label={translate('comment.edit.country')} />
        <TextField source="language" label={translate('comment.edit.language')} />
        <TextField source="title" maxRows={15} multiline label={translate('comment.edit.title')} />
        <TextInput source="text" maxRows={15} multiline label={translate('comment.edit.text')} />
      </SimpleForm>
    </div>
  );
};

export default CommentEdit;
