import {
  email,
  maxLength,
  regex,
  required,
} from 'react-admin';

const maxInputCharacterLenght = 50;
const maxEmailCharacterLenght = 254;
const validatePatternLetters = regex(/^[a-zA-ZA-zÀ-ú\x27ñç\s]+$/);

const UserValidation = {
  validateName: [required(), maxLength(maxInputCharacterLenght), validatePatternLetters],
  validateLastName: [required(), maxLength(maxInputCharacterLenght), validatePatternLetters],
  validateUsername: [required(), email(), maxLength(maxEmailCharacterLenght)],
  validateActive: [required()],
};

export default UserValidation;
