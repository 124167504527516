import { ApolloLink } from '@apollo/client';

const RefreshMiddleware = new ApolloLink((operation, forward) => {
  const apolloToken = localStorage.getItem('token');
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: apolloToken ? `Bearer ${apolloToken}` : '',
    },
  }));

  return forward(operation);
});

export default RefreshMiddleware;
