import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import {
  useTranslate,
  Datagrid,
  Filter,
  List,
  SimpleList,
  TextInput,
  TextField,
  BooleanField,
  DateInput,
  DateField,
  Pagination,
  CheckboxGroupInput,
  downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

import { LinkTextField, ListActions } from '../../lib/components';

const MAX_LIMIT_EXPORTS = 15000;

const exporter = (users) => {
  const usersExport = users.map((user) => {
    const {
      id, username, name, lastName, conditions, communicationsPolicy, legacyContext,
      dataPolicy, active, activatedAt, createdAt, updatedAt, imageURL, role,
    } = user;
    return {
      id,
      username,
      name,
      lastName,
      conditions,
      communicationsPolicy,
      dataPolicy,
      active,
      activatedAt,
      createdAt,
      updatedAt,
      imageURL,
      role,
      monavisId: legacyContext ? legacyContext.monavisId : undefined,
      sazId: legacyContext ? legacyContext.sazId : undefined,
    };
  });
  jsonExport(usersExport, {
    headers: [
      'id',
      'username',
      'name',
      'lastName',
      'conditions',
      'communicationsPolicy',
      'dataPolicy',
      'active',
      'activatedAt',
      'createdAt',
      'updatedAt',
      'imageURL',
      'role',
      'monavisId',
      'sazId',
    ],
  }, (err, csv) => {
    downloadCSV(csv, 'users'); // download as 'posts.csv` file
  });
};

const UserPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const UserFilter = ({ translate, permissions, ...props }) => (
  <Filter {...props} data-testid="user-search">
    <TextInput source="username" alwaysOn label={translate('user.filter.username')} />
    <DateInput source="activatedAt" alwaysOn label={translate('user.filter.activatedAt')} />
    <CheckboxGroupInput
      source="active"
      choices={[
        { id: true, name: 'Active' },
        { id: false, name: 'Inactive' },
      ]}
      alwaysOn
      label={translate('user.filter.active')}
    />
  </Filter>
);

const UserList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const { basePath } = props;
  return (
    <List
      {...props}
      actions={<ListActions hasCreate={false} maxResults={MAX_LIMIT_EXPORTS} exporter={exporter} />}
      filters={<UserFilter translate={translate} permissions={permissions} />}
      sort={{
        field: 'username',
        order: 'ASC',
      }}
      pagination={<UserPagination />}
      bulkActionButtons={false}
    >
      {useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
        <SimpleList
          primaryText={record => record.name}
          data-testid="user-list-mobile-data-grid"
        />
      ) : (
        <Datagrid
          rowClick="edit"
          optimized
          data-testid="user-list-data-grid"
        >
          <LinkTextField basePath={basePath} permissions={permissions} source="username" label={translate('user.list.username')} />
          <TextField source="name" sortable={false} label={translate('user.list.name')} />
          <TextField source="lastName" sortable={false} label={translate('user.list.lastName')} />
          <BooleanField source="active" sortable={false} label={translate('user.list.active')} />
          <DateField source="activatedAt" label={translate('user.list.activatedAt')} />
        </Datagrid>
      )}
    </List>
  );
};


export default UserList;
