import * as React from 'react';
import {
  useDataProvider, useNotify, useRedirect, Button,
} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import { parseCreateProfile } from '../../../lib/utils';

const CreateBtn = ({ form }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const create = () => dataProvider
    .create('profiles', { data: { ...parseCreateProfile(form) } })
    .then(() => {
      // success side effects go here
      const { userId } = form;
      redirect(`/users/${userId}/1`);
      notify('profile created');
    })
    .catch((error) => {
      // failure side effects go here
      notify(`Error: ${error.message}`, 'warning');
    });

  return (
    <Button
      variant="contained"
      color="primary"
      label="Create profile"
      onClick={create}
      startIcon={<SaveIcon />}
    />
  );
};
export default CreateBtn;
