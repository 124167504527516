import { Loading, useQuery } from 'react-admin';
import React, { cloneElement } from 'react';
import { Box } from '@material-ui/core';


const ProfileController = (props) => {
  const { id, children } = props;
  const { data: profile, loading: loadingRecord, error } = useQuery({
    type: 'GET_ONE',
    resource: 'profiles',
    payload: { id },
  });
  const zero = 0;
  if (loadingRecord) { return <Loading />; }
  if (error) { return <p>{error}</p>; }
  if (!profile) { return null; }

  return (

    <Box p="1em">
      <Box display="flex" justifyContent="space-between" width="100%">
        {cloneElement(children, {
          record: {
            ...profile,
            userId: id,
            createdMode: Object.getOwnPropertyNames(profile).length === zero,
          },
        })}
      </Box>
    </Box>
  );
};

export default ProfileController;
