import React, { useEffect, useState } from 'react';
import {
  useMutation, useNotify, useRefresh, useTranslate,
} from 'ra-core';
import { SelectInput, SimpleForm } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';

import { commentStatusStyles } from '../../resources/comments/styles';
import { STATUS_TYPES } from '../utils/Comments';

const ReviewsStatusSelect = ({ record }) => {
  const classes = commentStatusStyles();
  const { id } = record;
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const [input, setInput] = useState(null);

  const [sendStatus, { loading }] = useMutation(
    {
      type: 'UPDATE',
      resource: 'comments',
      payload: {
        id: `${id}`,
        data: { ...input },
      },
    },
    {
      onSuccess: () => {
        notify(translate('resources.comments.status.success'), 'success');
        refresh();
      },
      onFailure: (error) => {
        notify(error?.message, 'error');
      },
    },
  );


  useEffect(() => {
    if (input && !loading) {
      sendStatus();
      setInput(null);
    }
  }, [input, loading, sendStatus]);


  const handleChange = (event) => {
    const sendInput = {
      certified: record?.certified,
      status: event.target.value,
      text: record?.text,
    };

    setInput(sendInput);
  };
  if (loading) {
    return <CircularProgress className={classes.icon} color="primary" />;
  }
  return (
    <SimpleForm toolbar={null} record={record} className={classes.form}>
      <SelectInput
        onChange={handleChange}
        source="status"
        choices={[
          { id: STATUS_TYPES.REJECTED, name: STATUS_TYPES.REJECTED },
          { id: STATUS_TYPES.PUBLISHED, name: STATUS_TYPES.PUBLISHED },
        ]}
        label=""
        formClassName={classes.status}
        fullWidth
      />
    </SimpleForm>
  );
};

export default ReviewsStatusSelect;
