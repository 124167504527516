import React from 'react';
import {
  Button, useMutation, useRedirect, useNotify,
} from 'react-admin';
import ContentAdd from '@material-ui/icons/Add';
import { RETAILERS } from '../../resources/retailers/constants';


const CreateEmptyButton = ({
  basePath, resource, variant, module,
}) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const [create, { loading }] = useMutation({
    type: 'create',
    resource,
    payload: {},
  },
  {
    onSuccess: ({ data }) => {
      if (module === RETAILERS) {
        notify(`${module} with id ${data.id} created`);
      } else {
        notify(`${module} with key ${data.key} created`);
      }

      redirect(`${basePath}/${data.id}`); // redirect to edit page
    },
    onFailure: error => notify(`unable to create new ${module}: ${error && error.message}`, 'warning'),
  });
  return (
    <Button
      label="Create"
      variant={variant}
      onClick={() => create()}
      disabled={loading}
    ><ContentAdd />
    </Button>
  );
};
export default CreateEmptyButton;
