import * as React from 'react';
import {
  useDataProvider, useNotify, useRedirect, Button,
} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import { parseEditProfile } from '../../../lib/utils';

const EditBtn = ({ form }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const edit = () => dataProvider
    .update('profiles', { data: { ...parseEditProfile(form) } })
    .then(() => {
      // success side effects go here
      const { userId } = form;
      redirect(`/users/${userId}/1`);
      notify('profile updated');
    })
    .catch((error) => {
      // failure side effects go here
      notify(`Error: ${error.message}`, 'warning');
    });

  return (
    <Button
      variant="contained"
      color="primary"
      label="Edit profile"
      onClick={edit}
      startIcon={<SaveIcon />}
    />
  );
};
export default EditBtn;
