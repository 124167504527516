import * as React from 'react';
import {
  FormWithRedirect,
  FormDataConsumer,
  DateInput,
  TextInput,
  useTranslate,
  SelectInput,
  BooleanInput,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { Box, Toolbar, Typography } from '@material-ui/core';
import CreateBtn from './buttons/CreateBtn';
import EditBtn from './buttons/EditBtn';

const ProfileForm = (props) => {
  const translate = useTranslate();
  const petsOptions = [
    { id: 'DOG', name: 'Dog' },
    { id: 'CAT', name: 'Cat' },
    { id: 'BIRD', name: 'Bird' },
    { id: 'MOUSE', name: 'Mouse' },
    { id: 'FISH', name: 'Fish' },
    { id: 'OTHER', name: 'Other' },
  ];
  const genderOptions = [
    { id: 'MALE', name: translate('user.edit.gender.man') },
    { id: 'FEMALE', name: translate('user.edit.gender.woman') },
    { id: '', name: translate('user.edit.gender.none') },
  ];
  const numberOfHomeComponentsOptions = [
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
    { id: 'More than 5', name: '5+' },
  ];
  const educationOptions = [
    { id: '0', name: 'Level 0: Pre-primary education' },
    { id: '1', name: 'Level 1: Primary education or first stage of basic education' },
    { id: '2', name: 'Level 2: Lower secondary education or second stage of basic education' },
    { id: '3', name: 'Level 3: Upper secondary education' },
    { id: '4', name: 'Level 4: Post-secondary non-tertiary education' },
    { id: '5', name: 'Level 5: Short-cycle tertiary education' },
    { id: '6', name: 'Level 6: Bachelor\'s or equivalent' },
    { id: '7', name: 'Level 7: Master\'s or equivalent' },
    { id: '8', name: 'Level 8: Doctoral or equivalent' },
  ];
  return (
    <FormWithRedirect
      {...props}
      render={() => (
        <form style={{ width: '100%' }}>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box flex={1} style={{ marginRight: '10px' }}>
                <SelectInput
                  fullWidth
                  source="gender"
                  choices={genderOptions}
                  label={translate('user.edit.gender.title')}
                />
              </Box>
              <Box flex={1}>
                <DateInput fullWidth source="birthDate" label={translate('user.edit.birth')} />
              </Box>
            </Box>
            <Typography variant="h2" color="primary" paragraph style={{ marginLeft: '10px' }}>
              Address info
            </Typography>
            <Box display="flex">
              <Box flex={1} style={{ marginRight: '10px' }}>
                <TextInput fullWidth source="address.postalCode" label={translate('user.edit.postalCode')} />
              </Box>
              <Box flex={1} style={{ marginRight: '10px' }}>
                <TextInput fullWidth source="address.city" label={translate('user.edit.city')} />
              </Box>
              <Box flex={1}>
                <TextInput fullWidth source="address.state" label={translate('user.edit.state')} />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} style={{ marginRight: '10px' }}>
                <TextInput fullWidth source="address.line1" label={translate('user.edit.address_1')} />
              </Box>
              <Box flex={1} style={{ marginRight: '10px' }}>
                <TextInput fullWidth source="address.line2" label={translate('user.edit.address_2')} />
              </Box>
            </Box>
            <Typography variant="h2" color="primary" paragraph style={{ marginLeft: '10px' }}>
              Preferences
            </Typography>
            <Box display="flex">
              <Box flex={1} style={{ marginRight: '10px' }}>
                <BooleanInput
                  source="preference.alcohol"
                  label={translate('user.edit.preferences.alcohol')}
                />
              </Box>
              <Box flex={1} style={{ marginRight: '10px' }}>
                <BooleanInput
                  source="preference.bio"
                  label={translate('user.edit.preferences.bio')}
                />
              </Box>
              <Box flex={1} style={{ marginRight: '10px' }}>
                <BooleanInput
                  source="preference.light"
                  label={translate('user.edit.preferences.light')}
                />
              </Box>
              <Box flex={1} style={{ marginRight: '10px' }}>
                <BooleanInput
                  source="preference.noGluten"
                  label={translate('user.edit.preferences.gluten')}
                />
              </Box>
            </Box>
            <Typography variant="h2" color="primary" paragraph style={{ marginLeft: '10px' }}>
              Pets
            </Typography>
            <Box display="flex">
              <Box flex={1} style={{ marginRight: '10px' }}>
                <CheckboxGroupInput
                  source="pets"
                  choices={petsOptions}
                  label=""
                />
              </Box>
            </Box>
            <Typography variant="h2" color="primary" paragraph style={{ marginLeft: '10px' }}>
              Partner
            </Typography>

            <Box display="flex">
              <Box flex={1} style={{ marginRight: '10px' }}>
                <SelectInput
                  fullWidth
                  source="partner.gender"
                  choices={genderOptions}
                  label={translate('user.edit.gender.title')}
                />
              </Box>
              <Box flex={1} style={{ marginRight: '10px' }}>
                <DateInput fullWidth source="partner.birthDate" label={translate('user.edit.birth')} />
              </Box>
              <Box flex={1} style={{ marginRight: '10px' }}>
                <TextInput fullWidth source="partner.firstName" label={translate('user.edit.firstName')} />
              </Box>
              <Box flex={1} style={{ marginRight: '10px' }}>
                <SelectInput
                  fullWidth
                  source="numberOfHomeComponents"
                  choices={numberOfHomeComponentsOptions}
                  label={translate('user.edit.numberOfHomeComponentsOptions')}
                />
              </Box>
            </Box>
            <Typography variant="h2" color="primary" paragraph style={{ marginLeft: '10px' }}>
              Education Level
            </Typography>
            <Box display="flex">
              <Box flex={1} style={{ marginRight: '10px' }}>
                <SelectInput
                  fullWidth
                  source="educationCode"
                  choices={educationOptions}
                  label={translate('user.edit.educationOptions')}
                />
              </Box>
            </Box>
            <Typography variant="h2" color="primary" paragraph style={{ marginLeft: '10px' }}>
              Children
            </Typography>
            <Box display="flex">
              <Box flex={1} style={{ marginRight: '10px' }}>
                <ArrayInput source="children" label="">
                  <SimpleFormIterator>
                    <DateInput source="birthDate" label={translate('user.edit.birthDate')} />
                    <TextInput source="firstName" label={translate('user.edit.firstName')} />
                  </SimpleFormIterator>
                </ArrayInput>
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex">
              <FormDataConsumer>
                {({ formData, ...rest }) => (formData.createdMode === true
                  ? <CreateBtn form={formData} {...rest} />
                  : <EditBtn form={formData} {...rest} />)
                }
              </FormDataConsumer>
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
};

export default ProfileForm;
